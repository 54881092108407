import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApi } from "../../helpers/api";
import initialStates from "./state";

// Get All League
export const getLeagueList = createAsyncThunk(
  "getLeagueList",
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axiosApi.get(
        `league/league-list`
      );
      return { res: response.data?.data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Get All Match
export const getAllMatch = createAsyncThunk(
  "getAllMatch",
  async ({ page, leagueId }, { rejectWithValue }) => {
    try {
      const response = await axiosApi.get(
        `match/get-all-match-by-league?page=${page}&limit=50&division=${leagueId}`
      );
      return { res: response.data?.data, page };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Get Match History
export const getMatchHistory = createAsyncThunk(
  "getMatchHistory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosApi.get(`score/score-history/${id}`);
      return response.data?.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Get Single Match
export const getSingleMatch = createAsyncThunk(
  "getSingleMatch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosApi.get(`match/get-match-by-id/${id}`);
      return response.data?.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Get Total Score
export const getTotalScore = createAsyncThunk(
  "getTotalScore",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosApi.get(`score/total-score/${id}`);
      return response.data?.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

// create slice
const homeSlice = createSlice({
  name: "home",
  initialState: initialStates,
  extraReducers: {
    [getAllMatch.pending]: (state, action) => {
      state.status = state.data?.results?.length === 0 ? "loading" : "success";
    },
    [getAllMatch.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.data =
        action.payload.page === 1
          ? action.payload.res
          : {
              ...action.payload.res,
              results: [...state.data.results, ...action.payload.res.results],
            };
    },
    [getAllMatch.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getMatchHistory.pending]: (state, action) => {
      state.status = "loading";
    },
    [getMatchHistory.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.matchHistory = action.payload;
    },
    [getMatchHistory.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getSingleMatch.pending]: (state, action) => {
      state.status = "loading";
    },
    [getSingleMatch.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.match = action.payload;
    },
    [getSingleMatch.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getTotalScore.pending]: (state, action) => {
      state.status = "loading";
    },
    [getTotalScore.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.score = action.payload;
    },
    [getTotalScore.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
  reducers: {},
});

const { reducer } = homeSlice;
export default reducer;
