const state = {
  status: "loading",
  error: null,
  data: {},
  score: {},
  match: {},
  matchHistory: [],
};

export default state;
