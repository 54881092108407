import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Loader from "./components/Loader";
import AppRoutes from "./routes";
import store from "./store/store";

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <AppRoutes />
        </Provider>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
