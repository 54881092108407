import React, { lazy } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

const Home = lazy(() => import("../pages/home"));
const Scoring = lazy(() => import("../pages/scoring"));
const PrivacyPolicy = lazy(() => import("../pages/settings/privacy-policy"));
const TermsAndConditions = lazy(() => import("../pages/settings/terms-and-conditions"));
const Match = lazy(() => import("../pages/match"));

const Layout = () => {
  return (
    <main>
      
      <Outlet />
    </main>
  );
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        {/* <Route path="/" index element={<Home />} /> */}
        <Route path="/" index element={<Scoring />} />
        {/* <Route path="/livescore" index element={<Scoring />} /> */}
        <Route path="/privacy-policy" index element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" index element={<TermsAndConditions />} />
        <Route path="/match/:id" index element={<Match />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
