import { configureStore } from '@reduxjs/toolkit';
import { getDefaultMiddleware } from '@reduxjs/toolkit';

import homeReducer from './home/slice'; // home reducer

const rootReducer = {
  home: homeReducer,
};
const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
});
export default configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});
